import max from 'lodash/max';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Responsive } from 'react-grid-layout';
import { Link, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddchartIcon from '@mui/icons-material/Addchart';
import SaveIcon from '@mui/icons-material/Save';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import {
  CREATE_DASHBOARD,
  STAR_DASHBOARD,
  UPDATE_DASHBOARD,
  UNSTAR_DASHBOARD,
  GET_DASHBOARD_BY_ID,
  DELETE_DASHBOARD,
} from 'graphql/dashboards/queries';
import { useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { useSelector } from 'store';
import PageContainer from 'components/PageContainer';
import WidthProvider from 'components/WidthProvider';
import DashboardSettings from './DashboardSettings';
import Panel from './Panel';
import Box from '@mui/material/Box';
import EditPanelModal from './EditPanelModal';
import { useStyles } from '../styles';
import DateRangePicker from 'components/DateRangePickerNew';
import { toast } from 'react-hot-toast';
import RefreshButtonGroup from './RefreshButtonGroup';
import { analyticsPage, analyticsTrack } from 'utils/segment';
import { shortId } from 'utils';
import VariablesBar from './VariablesBar';
import useQueryParams from 'hooks/useQueryParams';
import GridLayoutChild from 'components/GridLayoutChild';
import NewPanelWizard from './NewPanelWizard';
import { DEFAULT_PANEL_GRID_CONFIG } from '../utils';
import { useTheme } from '@mui/material/styles';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const LG = 1280;
const MD = 992;
const SM = 768;
const XS = 480;
const XXS = 0;

export const NEW_PANEL = {
  panel: {
    title: 'New Panel',
    axis: {
      x: { date_style: 'auto' },
    },
  },
};

const Dashboard = ({
  dashboard: dshbrd,
  showActionBtns,
  selectedPnl,
  isViewMode,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const queryParams = useQueryParams();
  const refreshIntervalRef = useRef(null);
  const [dashboard, setDashboard] = useState(dshbrd);
  const [selectedPanel, setSelectedPanel] = useState(() => {
    if (dshbrd.id) {
      return selectedPnl || null;
    }
  });
  const [showWizard, setShowWizard] = useState(
    !dshbrd.id && !queryParams.service,
  );
  const [openSettings, setOpenSettings] = useState();
  const [refreshHash, setRefreshHash] = useState(Date.now());
  const {
    workspace: { workspace },
  } = useSelector((s) => s);

  const [updateDashboard] = useMutation(UPDATE_DASHBOARD);
  const [createDashboard] = useMutation(CREATE_DASHBOARD);
  const [deleteDashboard] = useMutation(DELETE_DASHBOARD);
  const [starDashboard] = useMutation(STAR_DASHBOARD);
  const [unstarDashboard] = useMutation(UNSTAR_DASHBOARD);

  useEffect(() => {
    dshbrd && setDashboard(dshbrd);
  }, [dshbrd]);

  useEffect(() => {
    if (dashboard.data.refresh_interval) {
      refreshIntervalRef.current = setInterval(() => {
        onRefresh();
      }, dashboard.data.refresh_interval * 1000);
    }
    return () => clearInterval(refreshIntervalRef.current);
  }, []);

  const timeRange = queryParams.timeRange || dashboard.data.timeRange;
  const tz = queryParams.tz || dashboard.data.tz;

  const getNewPanelLayout = (id, duplicateFromId) => {
    if (duplicateFromId) {
      const from = dashboard.data.panel_layouts.find(
        (pl) => pl.i === duplicateFromId,
      );
      return [...dashboard.data.panel_layouts, { ...from, i: id }];
    }
    return [
      ...dashboard.data.panel_layouts,
      { ...DEFAULT_PANEL_GRID_CONFIG, y: lastRow + 1 || 0, i: id },
    ];
  };

  const onDuplicate = (duplicateFrom) => {
    const panel = {
      ...duplicateFrom,
      id: shortId(),
      title: duplicateFrom ? `${duplicateFrom.title} - Copy` : 'New Panel',
    };
    setDashboard({
      ...dashboard,
      data: {
        ...dashboard.data,
        panels: [...dashboard.data.panels, panel],
        panel_layouts: getNewPanelLayout(panel.id, duplicateFrom.id),
      },
    });
  };

  const onDeletePanel = (panelId) => {
    setDashboard({
      ...dashboard,
      data: {
        ...dashboard.data,
        panels: dashboard.data.panels.filter((panel) => panel.id !== panelId),
      },
    });

    analyticsTrack('Panel Deleted', {});
  };

  const onLayoutChange = (currentLayout) => {
    setDashboard({
      ...dashboard,
      data: {
        ...dashboard.data,
        panel_layouts: currentLayout,
      },
    });
  };

  const onToggleStar = async () => {
    if (dashboard.id) {
      if (!dashboard.is_starred) {
        await starDashboard({
          variables: {
            payload: {
              dashboard_id: dashboard.id,
            },
          },
          update: (cache) => {
            cache.writeQuery({
              query: GET_DASHBOARD_BY_ID,
              variables: {
                id: dashboard.id,
              },
              data: {
                dashboard: {
                  ...dashboard,
                  is_starred: {
                    dashboard_id: dashboard.id,
                  },
                },
              },
            });
          },
        });
        toast.success('Dashboard starred');
      } else {
        await unstarDashboard({
          variables: {
            dashboardId: dashboard.id,
          },
          update: (cache) => {
            cache.writeQuery({
              query: GET_DASHBOARD_BY_ID,
              variables: {
                id: dashboard.id,
              },
              data: {
                dashboard: {
                  ...dashboard,
                  is_starred: null,
                },
              },
            });
          },
        });

        toast.success('Dashboard unstarred');
      }
    } else {
      if (!dashboard.is_starred) {
        setDashboard({
          ...dashboard,
          is_starred: {},
        });
      } else {
        setDashboard({
          ...dashboard,
          is_starred: null,
        });
      }
    }
  };

  const onSave = async (from, { payload, postSave = null }) => {
    try {
      let createPayload;
      let savePayload;

      if (from === 'panel') {
        const newPanel = payload;
        if (!selectedPanel.panel.id) {
          createPayload = {
            ...dashboard,
            data: {
              ...dashboard.data,
              panels: [...dashboard.data.panels, newPanel],
              panel_layouts: getNewPanelLayout(newPanel.id),
            },
          };
        } else {
          createPayload = {
            ...dashboard,
            data: {
              ...dashboard.data,
              panels: dashboard.data.panels.map((panel) =>
                panel.id === newPanel.id ? newPanel : panel,
              ),
            },
          };
        }
        savePayload = {
          data: createPayload.data,
        };

        setSelectedPanel({ panel: newPanel });
        if (!dashboard.id && !postSave) {
          toast.success('Saved');
        }
      } else if (from === 'settings') {
        const values = payload;
        createPayload = {
          ...dashboard,
          ...values,
          data: { ...dashboard.data, ...values.data },
        };
        savePayload = {
          ...values,
          data: { ...dashboard.data, ...values.data },
        };
      } else if (from === 'variables-settings') {
        const variables = dashboard.data.variables || [];
        const newPayload =
          payload.config.queryType === 'regions'
            ? {
                ...payload,
                value: '$default',
              }
            : payload;

        const index = variables.findIndex((v) => v.id === newPayload.id);
        createPayload = {
          ...dashboard,
          data: {
            ...dashboard.data,
            variables:
              index === -1
                ? [...variables, newPayload]
                : variables.map((variable, i) =>
                    i === index ? newPayload : variable,
                  ),
          },
        };
        savePayload = {
          data: createPayload.data,
        };
      } else if (from === 'variables-settings-delete') {
        createPayload = {
          ...dashboard,
          data: {
            ...dashboard.data,
            variables: payload,
          },
        };
        savePayload = {
          data: createPayload.data,
        };
      } else if (from === 'dashboard') {
        createPayload = payload;
        savePayload = {
          data: createPayload.data,
        };
      }

      if (dashboard.id) {
        delete savePayload.is_starred;
        await updateDashboard({
          variables: {
            id: dashboard.id,
            dashboard: {
              ...savePayload,
            },
          },
          optimisticResponse: {
            __typename: 'Mutation',
            update_dashboards_by_pk: {
              ...savePayload,
            },
          },
        });

        analyticsTrack('Dashboard Edited', {});

        if (postSave) {
          await postSave();
        } else {
          toast.success('Saved');
        }
      } else {
        const { is_starred } = createPayload;
        delete createPayload.is_starred;

        const res = await createDashboard({
          variables: {
            dashboard: createPayload,
          },
        });

        analyticsTrack('Dashboard Created', {});

        const dashboardId = res.data.row.id;

        if (is_starred) {
          await starDashboard({
            variables: {
              payload: {
                dashboard_id: dashboardId,
              },
            },
          });
        }
        history.replace({
          pathname: `/${workspace.id}/dashboards/${dashboardId}`,
          state: !postSave
            ? {
                panel: payload,
              }
            : null,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onDelete = async () => {
    if (dashboard.id) {
      await deleteDashboard({
        variables: {
          id: dashboard.id,
        },
      });
    }
    analyticsTrack('Dashboard Deleted', {});

    toast.success(`Dashboard deleted`);
    history.replace(`/${workspace.id}/dashboards`);
  };

  const onRefresh = () => {
    setRefreshHash(Date.now());
    analyticsTrack('Refreshed', {});
  };

  const onDateChange = (input) => {
    setDashboard({
      ...dashboard,
      data: {
        ...dashboard.data,
        timeRange: input.value,
        tz: input.tz,
      },
    });
  };

  const setRefreshInterval = (refreshInterval) => {
    setDashboard({
      ...dashboard,
      data: {
        ...dashboard.data,
        refresh_interval: refreshInterval,
      },
    });

    refreshIntervalRef.current && clearInterval(refreshIntervalRef.current);
    if (refreshInterval) {
      refreshIntervalRef.current = setInterval(() => {
        onRefresh();
      }, refreshInterval * 1000);
    }
  };

  const {
    addPanel,
    cancel,
    save,
    refresh,
    settings,
    panelEdit,
    timeRangeBtn,
    goToEdit,
  } = showActionBtns;

  const children = useMemo(() => {
    return dashboard.data.panels.map((panel) => {
      return (
        <Panel
          key={panel.id}
          refreshHash={refreshHash}
          dshTimeRange={timeRange}
          dshTZ={tz}
          panel={panel}
          dashboardId={dashboard.id}
          showPanelEdit={panelEdit}
          onDeletePanel={() => onDeletePanel(panel.id)}
          setSelectedPanel={() => {
            setSelectedPanel({ panel });
            analyticsPage('Edit Panel', {
              section: dashboard.id ? 'Edit Dashboard' : 'Create Dashboard',
              flow: 'Metrics Dashboard',
            });
            if (isViewMode) {
              history.push({
                pathname: `/${workspace.id}/dashboards/${dashboard.id}/edit`,
                search: `?${new URLSearchParams(queryParams)}`,
                state: { panel },
              });
            }
          }}
          isViewMode={isViewMode}
          onDuplicatePanel={() => onDuplicate(panel)}
          resolvedVariablesValues={dashboard.data.variables}
          defaultRegion={dashboard.data.default_region}
        />
      );
    });
  }, [
    dashboard.data.panels,
    refreshHash,
    timeRange,
    tz,
    dashboard.data.variables,
    dashboard.data.panel_layouts,
    queryParams.viewPanel,
    theme.palette.mode,
  ]);

  const gridChildren = useMemo(
    () =>
      children.map((child) => (
        <GridLayoutChild key={child.key}>{child}</GridLayoutChild>
      )),
    [children],
  );

  const onVariableChange = (value, variable) => {
    const newDash = {
      ...dashboard,
      data: {
        ...dashboard.data,
        variables: dashboard.data.variables.map((v) =>
          v.id === variable.id ? { ...v, value } : v,
        ),
      },
    };
    setDashboard(newDash);
  };

  const onImport = (panels, variables) => {
    if (!panels) {
      setSelectedPanel(NEW_PANEL);
      return;
    }

    const importedPanels = panels.map((panel) => ({ ...panel, id: shortId() }));
    setDashboard({
      ...dashboard,
      data: {
        ...dashboard.data,
        panels: [...dashboard.data.panels, ...importedPanels],
        panel_layouts: [
          ...dashboard.data.panel_layouts,
          ...importedPanels.map((panel) => ({
            ...DEFAULT_PANEL_GRID_CONFIG,
            y: lastRow + 1 || 0,
            i: panel.id,
          })),
        ],
        variables: [...(dashboard.data.variables || []), ...variables],
      },
    });

    setShowWizard(false);
  };

  const variablesBar = useMemo(() => {
    return (
      <VariablesBar
        variables={dashboard.data.variables}
        onVariableChange={onVariableChange}
        defaultRegion={dashboard.data.default_region}
        timeRange={dashboard.data.timeRange}
      />
    );
  }, [dashboard]);

  const lastRow = useMemo(
    () => max(dashboard.data.panel_layouts.map((layout) => layout.y)),
    [dashboard.data.panel_layouts],
  );

  const { viewPanelIdx, resolved } = useMemo(() => {
    if (!queryParams.viewPanel || !children) {
      return { viewPanelIdx: -1, resolved: true };
    }
    const viewPanelIdx = children.findIndex(
      (panel) => panel.key === queryParams.viewPanel,
    );

    return {
      viewPanelIdx,
      resolved: true,
    };
  }, [queryParams.viewPanel, children]);

  useEffect(() => {
    if (resolved && queryParams.viewPanel) {
      if (viewPanelIdx === -1) {
        toast.error('Panel not found');
      } else {
        analyticsPage('View Panel', {
          section: isViewMode && 'View Dashboard',
          flow: 'Metrics Dashboard',
        });
      }
    }
  }, [viewPanelIdx, resolved, queryParams.viewPanel]);

  return (
    <>
      <PageContainer title={dashboard.name} sx={{ overflowX: 'clip' }}>
        <PageContainer.PageHeader
          primary={
            <Stack direction="row" sapcing={1} alignItems="center">
              <Tooltip title={dashboard.name} placement="top" arrow>
                <Typography
                  variant="h4"
                  sx={{
                    maxWidth: 500,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {dashboard.name}
                </Typography>
              </Tooltip>
              <IconButton onClick={onToggleStar}>
                {dashboard.is_starred ? (
                  <StarIcon size={48} />
                ) : (
                  <StarBorderIcon size={48} />
                )}
              </IconButton>
            </Stack>
          }
          actions={[
            <Stack key={0} direction="row" spacing={1} alignItems="center">
              {cancel && (
                <Button
                  size="small"
                  component={Link}
                  to={`/${workspace.id}/dashboards`}
                  variant="text"
                >
                  Cancel
                </Button>
              )}
              {save && (
                <IconButton
                  color="primary"
                  onClick={() => onSave('dashboard', { payload: dashboard })}
                  title={'Save'}
                  icon={<SaveIcon />}
                />
              )}
              {addPanel && (
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setShowWizard(true);
                    analyticsPage('Add Panel', {
                      section: dashboard.id
                        ? 'Edit Dashboard'
                        : 'Create Dashboard',
                      flow: 'Metrics Dashboard',
                    });
                  }}
                  title={'Add Panel'}
                  icon={<AddchartIcon />}
                />
              )}
              {goToEdit && (
                <IconButton
                  sx={{ backgroundColor: theme.palette.background.paper }}
                  title={'Edit Dashboard'}
                  icon={<EditIcon />}
                  to={`/${workspace.id}/dashboards/${dashboard.id}/edit`}
                />
              )}
              {timeRangeBtn && (
                <Box>
                  <DateRangePicker
                    size="small"
                    value={{
                      value: timeRange,
                      tz: tz,
                    }}
                    onChange={onDateChange}
                  />
                </Box>
              )}
              {refresh && (
                <RefreshButtonGroup
                  onRefresh={onRefresh}
                  refreshInterval={dashboard.data.refresh_interval}
                  setRefreshInterval={setRefreshInterval}
                />
              )}
              {goToEdit && (
                <IconButton
                  title={
                    queryParams.fullScreen ? 'Exit full screen' : 'Full screen'
                  }
                  sx={{ backgroundColor: theme.palette.background.paper }}
                  icon={
                    queryParams.fullScreen ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )
                  }
                  to={
                    queryParams.fullScreen
                      ? `/${workspace.id}/dashboards/${dashboard.id}`
                      : `/${workspace.id}/dashboards/${dashboard.id}?fullScreen=1`
                  }
                />
              )}
              {settings && (
                <IconButton
                  onClick={() => setOpenSettings(!openSettings)}
                  title={'Settings'}
                  icon={<SettingsIcon />}
                />
              )}
            </Stack>,
          ]}
        />
        {variablesBar}
        {viewPanelIdx === -1 ? (
          <WidthProvider debounce={30}>
            {(width) => {
              return (
                <Responsive
                  className="layout"
                  width={width}
                  layouts={{
                    lg: dashboard.data.panel_layouts,
                    md: dashboard.data.panel_layouts,
                    sm: dashboard.data.panel_layouts,
                    xs: dashboard.data.panel_layouts,
                    xxs: dashboard.data.panel_layouts,
                  }}
                  onLayoutChange={onLayoutChange}
                  isDraggable
                  isRearrangeable
                  isResizable
                  draggableHandle={`.${classes.gridItemTitle}`}
                  breakpoints={{ lg: LG, md: MD, sm: SM, xs: XS, xxs: XXS }}
                  cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                  useCSSTransforms={false}
                >
                  {gridChildren}
                </Responsive>
              );
            }}
          </WidthProvider>
        ) : (
          <Box height={window.innerHeight - 40}>
            {React.cloneElement(children[viewPanelIdx], {
              height: window.innerHeight - 40,
              isIsolatedView: true,
            })}
          </Box>
        )}

        {openSettings && (
          <DashboardSettings
            onClose={() => setOpenSettings(!openSettings)}
            onSave={onSave}
            onDelete={onDelete}
            dashboard={dashboard}
          />
        )}
        {selectedPanel && (
          <EditPanelModal
            panel={selectedPanel.panel}
            closeModal={() => {
              setSelectedPanel(null);
              setShowWizard(false);
            }}
            onSavePanel={onSave}
            variablesBar={variablesBar}
            resolvedVariablesValues={dashboard.data.variables}
            defaultRegion={dashboard.data.default_region}
          />
        )}
      </PageContainer>
      {showWizard && (
        <NewPanelWizard
          onImport={onImport}
          onClose={() => setShowWizard(false)}
          variables={dashboard.data.variables}
          defaultRegion={dashboard.data.default_region}
        />
      )}
    </>
  );
};

export default Dashboard;
